<template>
  <div>
    <el-form :inline="true">


<!--      <el-form-item>
        <el-input
            v-model="this.countId"

        >
        </el-input>

      </el-form-item>-->


      <el-form-item>
        <el-input
            v-model="searchForm.cardNo"
            placeholder="支付订单号"
            clearable
        >
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-input
            v-model="searchForm.orderRefund"
            placeholder="退款订单号"
            clearable
        >
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-input
            v-model="searchForm.usEmail"
            placeholder="充值用户"
            clearable
        >
        </el-input>

      </el-form-item>


      <el-form-item>

        <el-date-picker
            v-model="value2"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="支付开始日期"
            end-placeholder="支付结束日期"
            :default-time="['00:00:00','23:59:59']"
            align="right"
            @change="dateFormat"
        >
        </el-date-picker>

      </el-form-item>


      <el-form-item>
        <el-button @click="getUserList">搜索</el-button>
      </el-form-item>


    </el-form>

    <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @cell-dblclick="rowClick"
        border
        stripe
        @selection-change="handleSelectionChange">

      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column
          prop="id"
          label="id"
          width="70">
      </el-table-column>


      <el-table-column
          prop="orderNumber"
          label="支付订单号"
          width="210">
      </el-table-column>
      <el-table-column
          prop="orderRefund"
          label="退款订单号"
          width="210">
      </el-table-column>


      <el-table-column
          prop="userMail"
          label="退款用户"
          width="210">
      </el-table-column>

      <el-table-column
          prop="amount"
          label="订单支付金额"
          width="110">
      </el-table-column>

      <el-table-column
          prop="amountRefund"
          label="退款金额"
          width="100">
      </el-table-column>


      <el-table-column
          prop="paymentType"
          label="支付方式"
          width="80">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.paymentType === 'weixin'" type="primary">微信</el-tag>
          <el-tag size="small" v-else-if="scope.row.paymentType === 'alipay'" type="primary">支付宝</el-tag>


        </template>
      </el-table-column>

      <el-table-column
          prop="status"
          label="退款状态"
          width="80">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.status === '0'" type="danger">未退款</el-tag>
          <el-tag size="small" v-else-if="scope.row.status === '1'" type="success">已退款</el-tag>

          <el-tag size="small" v-else-if="scope.row.status === '2'" type="danger">退款失败</el-tag>
        </template>
      </el-table-column>

      <el-table-column
          prop="status"
          label="退款通知"
          width="80">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.status === '0'" type="danger">未通知</el-tag>
          <el-tag size="small" v-else-if="scope.row.status === '1'" type="success">已通知</el-tag>

          <el-tag size="small" v-else-if="scope.row.status === '2'" type="danger">通知但未成功</el-tag>
        </template>
      </el-table-column>


      <el-table-column
          prop="creationTime"
          label="创建支付时间"
          width="165">
      </el-table-column>
      <el-table-column
          prop="modifyTime"
          label="支付回调时间"
          width="165">
      </el-table-column>

      <el-table-column
          prop="icon"
          width="260px"
          label="操作">

        <template slot-scope="scope">

<!--
          <el-button type="text" @click="editHandle(scope.row.id)">查看订单状态 或 退款</el-button>
-->



        </template>
      </el-table-column>


    </el-table>

    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[15, 20, 50, 100]"
        :current-page="current"
        :page-size="size"
        :total="total">
    </el-pagination>


    <!--新增对话框-->
    <el-dialog
        title="支付订单查询详情 【请谨慎操作退款 并核对退款金额】"
        :visible.sync="dialogVisible"
        width="800px"
        :before-close="handleClose"
    >

      <el-form :model="editForm" :rules="editFormRules" ref="editForm">
        <!--        <el-form-item label="先查看今天数据是否入库" prop="accountNumber" >

                </el-form-item> :disabled="true"-->

        <el-row>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-form-item label="卡片订单号" prop="oriOrderCode" label-width="100px">
                <el-input v-model="editForm.oriOrderCode" autocomplete="off" :disabled="true"></el-input>
              </el-form-item>

              <el-form-item label="交易单号" prop="bankserial" label-width="100px">
                <el-input v-model="editForm.bankserial" autocomplete="off" :disabled="true"></el-input>
              </el-form-item>

              <el-form-item label="商户单号" prop="payOrderCode" label-width="100px">
                <el-input v-model="editForm.payOrderCode" autocomplete="off" :disabled="true"></el-input>
              </el-form-item>


            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">

              <el-form-item label="交易状态" prop="orderMsg" label-width="100px">
                <el-input v-model="editForm.orderMsg" autocomplete="off" ></el-input>
              </el-form-item>
              <el-form-item label="交易类型" prop="transType" label-width="100px">
                <el-input v-model="editForm.transType" autocomplete="off" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="充值金额" prop="amount" label-width="100px">
                <el-input v-model="editForm.amountT" autocomplete="off" :disabled="true" ></el-input>
              </el-form-item>

              <el-form-item label="退款金额" prop="amount" label-width="100px">
                <el-input v-model="seditForm.amount" autocomplete="off" ></el-input>
              </el-form-item>


            </div>
          </el-col>
        </el-row>


      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('editForm')">取 消</el-button>&nbsp;&nbsp;
        <el-popconfirm title="确认给用户退款吗？ 请核对退款金额。" @confirm="submitForm('editForm')">

          <el-button type="primary" slot="reference">确认 退款</el-button>
        </el-popconfirm>

      </div>
    </el-dialog>


    <!-- 分配权限对话框 -->
    <el-dialog title="分配角色" :visible.sync="roleDialogFormVisible" width="600px">

      <el-form :model="roleForm">
        <el-tree
            :data="roleTreeData"
            show-checkbox
            ref="roleTree"
            :check-strictly=checkStrictly
            node-key="id"
            :default-expand-all=true
            :props="defaultProps">
        </el-tree>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="roleDialogFormVisible=false">取 消</el-button>
        <el-button type="primary" @click="submitRoleHandle('roleForm')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "Role",
  data() {
    return {
      options: [],
      searchForm: {},
      delBtlStatu: true,

      total: 0,
      size: 15,
      current: 1,
      countId: 0,
      dialogVisible: false,
      editForm: {},
      seditForm: {	},
      pickerOptions: {
        shortcuts: [{
          text: '最近一天',
          onClick(picker) {
            const end = new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999));
            const start = new Date(new Date(new Date().getTime()).setHours(0, 0, 0, 0));
            picker.$emit('pick', [start, end]);

          }
        }, {
          text: '昨天',
          onClick(picker) {
            const start = new Date(new Date(new Date().getTime() - 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0));
            const end = new Date(new Date(new Date().getTime() - 24 * 60 * 60 * 1000).setHours(23, 59, 59, 999));
            picker.$emit('pick', [start, end]);
          }
        }
          , {
            text: '最近一周',
            onClick(picker) {
              const end = new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999));//new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999));//new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999));//new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);

            }
          }]
      },
      value2: '',
      tableData: [],

      editFormRules: {

      },

      multipleSelection: [],

      roleDialogFormVisible: false,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      roleForm: {},
      roleTreeData: [],
      treeCheckedKeys: [],
      checkStrictly: true

    }
  },
  created() {


    this.getUserList()


    this.$axios.get("/sys/roleMGQZHP/list").then(res => {
      this.roleTreeData = res.data.data.records
    })
  },
  methods: {
    dateFormat(picker) {
      /* 法一 */
      console.log("11" + picker.length)
      console.log(picker[0].toString())
      console.log(picker[1].toString())
      this.searchForm.creationTime = picker[0].toString() + "," + picker[1].toString();

    },
    rowClick(row, column, event) {
      this.editHandle(row.id)
      console.log(row);///获取该行的所有数据
      console.log(column);
      console.log(event);
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      console.log("勾选")
      console.log(val)
      this.multipleSelection = val;

      this.delBtlStatu = val.length == 0
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getUserList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val
      this.getUserList()
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false
      this.editForm = {}
    },
    handleClose() {
      this.resetForm('editForm')
    },

    getUserList() {


      if (this.value2 == null || this.value2 == '') {
        console.log("112" + this.value2)
        this.searchForm.creationTime = '';
      }


      this.$axios.get("/card/orderRefund/listS", {
        params: {
          usEmail: this.searchForm.usEmail,
          cardNo: this.searchForm.cardNo,
          cardId: this.searchForm.cardId,
          type: this.searchForm.type,
          creationTime: this.searchForm.creationTime,
          orderRefund: this.searchForm.orderRefund,

          current: this.current,
          size: this.size
        }
      }).then(res => {
        this.tableData = res.data.data.records
        this.size = res.data.data.size
        this.current = res.data.data.current
        this.total = res.data.data.total
        this.countId = res.data.data.countId
      })
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
        
          this.$axios.post('/card/orderRefund/' + (this.seditForm.id ? 'update' : 'save'), this.seditForm)
              .then(res => {
                this.$message({
                  showClose: true,
                  message: '恭喜你，操作成功',
                  type: 'success',
                  onClose: () => {
                    this.getUserList()
                  }
                });

                this.dialogVisible = false
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    editHandle(id) {
      this.$axios.get('/card/order/info/' + id).then(res => {

        this.editForm = JSON.parse(res.data.data.wcOrderNumber);
        this.seditForm = res.data.data;

        this.editForm.amountT = this.seditForm.amount.toString();

        //this.editForm.money = this.seditForm.amount.toString();
        /*   alert();*/

        this.dialogVisible = true
      })
    },
    editHandleT(id) {
      this.$axios.get('http://visa.fomepay.com/APay/page/share/showcard.html?cardId=' + id).then(res => {
        this.editForm = res.data.data

        this.dialogVisible = true
      })
    },
    delHandle(id) {

      var ids = []

      if (id) {
        ids.push(id)
      } else {
        this.multipleSelection.forEach(row => {
          ids.push(row.id)
        })
      }
      console.log(ids)
      //alert(ids);


      this.$axios.post("/card/order/delete", ids).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose: () => {
            this.getUserList()
          }
        });
      })
    },
    sa(id) {
      var ids = []

      if (id) {
        ids.push(id)
      } else {
        this.multipleSelection.forEach(row => {
          ids.push(row.id)
        })
      }
      console.log(ids)
      //alert(ids);
      this.$axios.post("/card/order/delete", ids).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose: () => {
            this.getUserList()
          }
        });
      })
    },

    roleHandle(id) {
      this.roleDialogFormVisible = true

      this.$axios.get('/sys/userWQKKDUXHAL/info/' + id).then(res => {
        this.roleForm = res.data.data

        let roleIds = []
        res.data.data.sysRoles.forEach(row => {
          roleIds.push(row.id)
        })

        this.$refs.roleTree.setCheckedKeys(roleIds)
      })
    },
    submitRoleHandle(formName) {
      var roleIds = this.$refs.roleTree.getCheckedKeys()

      console.log(roleIds)

      this.$axios.post('/sys/userWQKKDUXHAL/role/' + this.roleForm.id, roleIds).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose: () => {
            this.getUserList()
          }
        });

        this.roleDialogFormVisible = false
      })
    },
    repassHandle(id, username) {

      this.$confirm('将重置用户【' + username + '】的密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post("/sys/userWQKKDUXHAL/repass", id).then(res => {
          this.$message({
            showClose: true,
            message: '恭喜你，操作成功',
            type: 'success',
            onClose: () => {
            }
          });
        })
      })
    }
  }
}
</script>

<style scoped>

.el-pagination {
  float: right;
  margin-top: 22px;
}

</style>